@import '../../../../../styles/colors.scss';

.CareTeamCard {
  height: auto;
  width: 25rem;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  border-top: 2px solid $color-blue-subheader;
  margin: 2rem;

  &__row1 {
    display: flex;
    justify-content: center;
    border-bottom: 1px solid rgba(99, 99, 99, 0.096);

    &__col1 {
      margin-top: 1.5rem;
    }

    &__col2 {
      margin-left: 1rem;
      margin-top: 1.5rem;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }

    &__col2 > h3 {
      color: $color-blue-subheader;
      font-size: 1.5rem;
      font-weight: 600;
    }

    &__col2 > p {
      color: rgb(116, 116, 116);
      margin: 0.1rem 0;
      font-size: 1.1rem;
    }

    &__col2 > span {
      margin-top: 0.4rem;
      margin-bottom: 1.5rem;
      display: inline-block;

      border-radius: 20px;
      padding: 0.1rem 1rem;
      color: white;
      font-weight: 600;
      font-size: 0.9rem;
    }
  }

  &__row2 {
    display: flex;
    flex-direction: column;
    margin: 1rem 3.2rem;
    padding-bottom: 1rem;

    &__content {
      display: flex;
    }

    &__content > p {
      font-size: 1.1rem;
      font-weight: 300;
      margin-top: 0.2rem;
      color: black;
    }

    &__content > span {
      margin-right: 0.8rem;
      margin-top: 0.2rem;
      font-size: 1.2rem;
      color: $color-blue-subheader;
    }

    &__content-company {
      display: flex;
      align-items: center;

      p {
        font-size: 1.1rem;
        font-weight: 300;
        margin-top: 0.2rem;
        color: $blue-light;
      }

      p:hover {
        text-decoration: underline;
        cursor: pointer;
      }

      span {
        margin-right: 0.8rem;
        margin-top: 0.2rem;
        font-size: 1.2rem;
        color: $color-blue-subheader;
      }
    }
  }

  &__row2 > p:first-child {
    padding-top: 1rem;
    color: rgb(116, 116, 116);
    margin-bottom: 0.5rem;
    font-size: 1.1rem;
  }

  &__row3 {
    border-top: 1px solid rgba(99, 99, 99, 0.096);
    height: 4rem;
    display: flex;

    &__col1 {
      width: 33%;
      height: 100%;
      border-right: 1px solid rgba(99, 99, 99, 0.096);
      display: flex;
      justify-content: center;
      align-items: center;
    }
    &__col2 {
      width: 33%;
      height: 100%;
      border-right: 1px solid rgba(99, 99, 99, 0.096);
      display: flex;
      justify-content: center;
      align-items: center;
    }
    &__col3 {
      width: 33%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
