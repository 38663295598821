.CompanyDetailsPage {
  font-family: sans-serif;
  text-align: center;
  display: flex;
  flex-direction: column;

  &__col-1 {
    height: auto;
  }

  &__container-for-col-2-and-3 {
    display: flex;
    height: auto;

    &__col-2 {
      display: flex;
      justify-content: center;
      height: auto;
      width: 25vw;
    }

    &__col-3 {
      height: auto;
      width: 85vw;
    }
  }
}

//==============MEDIA CLASSES================//

.CompanyDetailsPage__tablet-container {
  display: flex;
  height: auto;
  flex-direction: column;

  &__col-2 {
    height: auto;
    display: flex;
    justify-content: center;
    width: 100vw;
  }

  &__col-3 {
    width: 100vw;
    height: auto;
  }
}
/* =================================== */
