@import '../../../../styles/colors.scss';

.HoursOfServiceForm {
  margin: 0.5rem 0;

  transition: ease-in-out;
  border-radius: 5px;
  // border: 2px solid $wizard-bg-color;
  width: 21rem;
  // background-color: $wizard-bg-color;
  padding: 0.5rem;
}

.ServiceDay {
  margin-bottom: 1rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  opacity: 0;

  &__times {
    &__na {
      width: 12rem;

      p {
        font-size: 1.1rem;
        font-weight: 600;
        color: $color-blue-subheader;
        opacity: 0.5;
      }
    }
  }

  h2 {
    font-size: 1.3rem;
    background-color: $color-blue-subheader;
    color: white;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 3rem;
    font-weight: 600;
    aspect-ratio: 1;
    user-select: none;
  }

  h2:hover {
    cursor: pointer;
  }
}

#marg-right {
  margin-right: 0.5rem;
}

.inact {
  opacity: 0.7;
  color: $color-blue-subheader !important;
  background-color: $wizard-bg-color !important;
}

@keyframes height-in {
  0% {
    height: 0;
  }
  100% {
    height: auto;
  }
}

.fade-in {
  animation: fade-in 300ms ease-out forwards 300ms;
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.react-timerange-picker__wrapper {
  background-color: $wizard-bg-color;
  padding: 0.4rem;
  border-radius: 5px;
  border: 1px solid transparent !important;
  font-size: 1.1rem;
  font-weight: 600;
}

.react-timerange-picker__inputGroup > span {
  color: $color-blue-subheader !important;
}

.react-timerange-picker__inputGroup__input:focus-visible {
  outline: none !important;
  user-select: none;
}

.react-timerange-picker__inputGroup__amPm {
  color: $color-blue-subheader !important;
}

.react-timerange-picker__inputGroup__input {
  color: $color-blue-subheader !important;
}

.react-timerange-picker__inputGroup__input:disabled {
  opacity: 1;
}

.HoursOfServiceContainer {
  animation: height-in 300ms forwards;
}
