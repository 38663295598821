@import '../../../styles/colors.scss';

.TierCard {
  border: $border-primary;
  border-radius: 8px;
  margin: 1rem;
  width: 30%;
  min-width: 28rem;
  height: auto;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  display: flex;
  flex-direction: column;
  align-items: center;

  &__price {
    margin-top: 1rem;
  }

  &__price > p {
    display: inline;
    font-size: 3rem;
    color: $color-blue-subheader;
    font-weight: 600;
  }

  &__price > span {
    color: $blue-light;
    font-weight: 600;
    font-size: 1.3rem;
  }

  &__desc {
    margin-top: 1rem;
    margin-bottom: 2rem;
  }

  &__desc > p {
    margin: 0.3rem;
    color: $color-blue-subheader;
    font-size: 1.2rem;
    font-weight: 500;
  }

  &__btn {
    margin-top: auto;
    margin-bottom: 0.8rem;
    width: 95%;
    padding: 0.8rem;
    font-family: $font-primary;
    color: white;
    font-weight: 600;
    font-size: 1.5rem;
    border-radius: 25px;
    border: 1px solid $color-blue-subheader;
    background-color: $color-blue-subheader;
    transition: all 300ms;
  }

  &__btn:hover {
    cursor: pointer;
    transform: translateY(-2px);
  }

  &__subhead {
    display: flex;
    align-items: center;
    padding-bottom: 1.5rem;
  }

  &__subhead > h5 {
    color: $blue-light;
    font-size: 1.6rem;
    font-weight: 600;
  }

  &__subhead > span {
    margin-right: 0.5rem;
    font-size: 2.5rem;
    color: rgb(250, 192, 1);
    transform: translateY(4px);
  }
}

.TierCard > h2 {
  color: $color-blue-subheader;
  margin-top: 1rem;
  font-size: 2rem;
  font-weight: 600;
}

.TierCard > hr {
  width: 100%;
  opacity: 0.2;
}

//CUSTOM CLASSES PROPS

.xtra {
  height: 32rem;
}

.invert {
  color: #fff;
  background-color: $blue-light;
  border: 1px solid $blue-light;
}
