@import '../../../../styles/variables';
@import '../../../../styles/colors.scss';

.textField {
  text-align: left;
  display: block;
  width: 100%;
  max-width: 50rem;
  margin-bottom: 3rem;
  position: relative;

  input {
    font-size: 1em;
    padding: $size-xs 0;
    display: block;
    width: 100%;
    max-width: 50rem;
    background-color: rgb(232, 241, 253) !important;
    border: none;
    border-bottom: 1px solid $primary-color;
    &:focus {
      outline: none;
    }
    &::placeholder {
      font-family: $font-primary;
      color: rgb(175, 175, 175);
    }
  }

  label {
    color: rgb(146, 146, 146);
    font-weight: 600;
    position: absolute;
    pointer-events: none;
    top: -0.4em;
    left: 0;
    transition: 0.2s ease all;
  }

  input:focus ~ label,
  input:not([value='']) ~ label {
    font-size: 0.8em;
  }

  input:focus ~ label {
    color: $color-blue-subheader;
  }

  .bar {
    position: relative;
    display: block;
    width: 94%;
    transform: translateX(12px);
    &:before,
    &:after {
      content: '';
      height: 2px;
      width: 0;
      bottom: 0;
      position: absolute;
      background: $color-blue-subheader;
      transition: 0.2s ease all;
    }
    &:before {
      left: 50%;
    }
    &:after {
      right: 50%;
    }
  }

  input:focus ~ .bar:before,
  input:focus ~ .bar:after {
    width: 50%;
  }
  &.error {
    input {
      border-color: $error-color;
    }
    color: $error-color;
  }
}
