@import '../../../../styles/colors.scss';

.SummaryForm {
  display: flex;
  min-height: 35vh;
  margin-bottom: 1rem;

  &__content {
    padding: 1rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 50%;
    justify-content: flex-start;
    // border: 1px solid red;
    // flex-wrap: wrap;
    hyphens: auto;
  }

  &__content > h2 {
    color: $color-blue-subheader;
    font-size: 2rem;
    margin-bottom: 1rem;
    font-weight: 800;
    text-decoration: underline;
  }

  &__content > h3 {
    font-size: 1.4rem;
    color: rgb(151, 174, 211);
  }

  &__content > p {
    color: black;
    font-size: 1.1rem;
    font-weight: 300;
    margin-bottom: 1rem;
  }
}

.insurableDetails {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.insurableDetails > h3 {
  font-size: 1.4rem;
  color: rgb(151, 174, 211);
}

.insurableDetails > p {
  color: black;
  font-size: 1.1rem;
  font-weight: 300;
  margin-bottom: 1rem;
}

.attached-files {
  margin-top: 1rem;
}

.attached-files > h3 {
  color: $color-blue-subheader;
  font-size: 2rem;
  margin-bottom: 1rem;
  font-weight: 800;
  text-decoration: underline;
}

.attached-files > p {
  color: black;
  font-size: 1rem;
  font-weight: 300;
  margin-bottom: 1rem;
}

.attached-files > p > span {
  margin-right: 0.2rem;
  font-weight: 600;
  border: 1px solid transparent;
  transition: 200ms;
  border-radius: 50%;
  padding: 0 0.3rem;
}

.attached-files > p > span:hover {
  cursor: pointer;
  background-color: rgb(239, 245, 253);
  border: 1px solid rgb(239, 245, 253);
}
