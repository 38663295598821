.App {
  text-align: center;
  height: 100vh;
}

#images {
  white-space: nowrap;
}

.modal-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
}

.modal {
  z-index: 100;
  background: white;
  position: relative;
  margin: 1.75rem auto;
  border-radius: 3px;
  max-width: 500px;
  padding: 2rem;
}

.modal-header {
  display: flex;
  justify-content: flex-end;
}

.modal-close-button {
  font-size: 1.4rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  opacity: 0.3;
  cursor: pointer;
  border: none;
}

/* Material UI CSS battles */
.MuiContainer-root {
  width: auto !important;
  padding: 0 !important;
  margin: 0 !important;
  font-family: 'Poppins', sans-serif !important;
}

.MuiInputBase-input {
  font-family: 'Poppins', sans-serif !important;
}

.MuiIconButton-root {
  padding: 6px !important;
}

.MuiIconButton-root:hover {
  padding: 6px !important;
}
