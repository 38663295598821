.FileUploadTeam {
  margin-top: 1.5rem;
  margin-bottom: 1rem;
  border-radius: 50%;
  padding: 1.5rem;
  width: 65%;
  height: 11rem;
  font-family: 'Poppins', sans-serif;
  font-weight: 300;
  font-size: 1.1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &__Container {
    margin-left: -2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 0 2rem;
  }
}

.FileUpload > img:hover {
  cursor: pointer;
}

.FileUploadTeam__text > label {
  color: rgb(58, 110, 252);
  text-decoration: underline;
}

.FileUploadTeam__text {
  color: black;
  font-size: 1rem;
  font-weight: 300;
}

.FileUploadTeam__text > label:hover {
  cursor: pointer;
}

.FileUploadTeam__upload-details {
  color: black;
  font-weight: 300;
}

#subTextTeam {
  color: rgb(146, 145, 145);
  font-size: 0.9rem;
  margin-top: 0.5rem;
}

#label:hover {
  cursor: pointer;
}

#file {
  font-size: 0.8rem;
}
