@import '../../../../styles/colors.scss';

.MapFormModal {
  display: flex;
  flex-direction: column !important;
  align-items: center;
  margin-bottom: 2rem;

  h3 {
    font-size: 1.8rem;
    color: $color-blue-subheader;
    margin-top: -2rem;
    margin-bottom: 2rem;
  }

  p {
    font-size: 1.3rem;
    font-weight: 300;
    margin-bottom: 0.5rem;
  }

  &__buttons {
    button {
      border: none;
      padding: 0.5rem 1.5rem !important;
    }

    button:nth-child(2) {
      color: white !important;
      background-color: $color-blue-subheader !important;
    }

    button:hover {
      cursor: pointer;
    }
  }
}
