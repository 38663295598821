@import '../../styles/colors.scss';

.CustomSpinner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &__container {
    height: 6.3rem;
    width: 6.3rem;
    padding: 0.4rem;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    animation: pulse 2s infinite;
    background-color: $color-blue-subheader;

    img {
      background-color: $color-blue-subheader;
      height: 4.8rem;
      width: 4.8rem;
      object-fit: fill;
      border-radius: 20px;
    }
  }
}

@keyframes pulse {
  0% {
    transform: translateX(0);
    box-shadow: 0 0 0 0 rgba(26, 80, 161, 0.479);
  }

  50% {
    transform: translateX(40px);
  }

  70% {
    box-shadow: 0 0 0 15px rgba(255, 121, 63, 0);
  }

  100% {
    transform: translateX(0);
    box-shadow: 0 0 0 0 rgba(255, 121, 63, 0);
  }
}
