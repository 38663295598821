@import '../../../../styles/colors.scss';

//CONTAINER THAT HOLD CARDS FOR SERVICES/ SERVICE TEAMS/ LOCATION
.ServiceTeamTab__container {
  display: flex;
  flex-direction: row;
  width: 100%;
  flex-wrap: wrap;
  height: auto;
  min-height: 40vh;
  padding-bottom: 5rem;
}

@media only screen and (max-width: 900px) {
  .ServiceTeamTab__container {
    justify-content: center;
    width: 100vw;
  }
}

.ServiceTeamTab__input::placeholder {
  color: rgba(26, 80, 161, 0.216);
}

.ServiceTeamTab__input {
  transition: all 300ms;
  margin-left: 0.5rem;
}

.ServiceTeamTab__input:focus {
  margin-left: 0rem;
  width: 23.5rem !important;
  outline: none;
}

.ServiceTeamTab__Add-New {
  height: 24.7rem;
  width: 25rem;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  border-top: 2px solid $color-blue-subheader;
  margin: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &__Plus {
    font-size: 4rem;
    margin-bottom: 1.5rem;
    color: $blue-light;
    transition: 300ms;
  }

  &__Plus:hover {
    cursor: pointer;
    transform: scale(1.05);
  }
}

.ServiceTeamTab__Add-New > p {
  color: $color-blue-subheader;
  font-weight: 600;
  font-size: 1.5rem;
}
