@import '../../../../styles/colors.scss';

.ServiceTypeChecker {
  p {
    color: black;
    font-size: 1.2rem;
    margin-bottom: 2rem;
    margin-top: -0.2rem;
  }
}
