@import '../../styles/colors.scss';

.CustomIcon > * {
  font-size: 1.4rem;
}

.CustomIcon {
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
