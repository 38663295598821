/*main elements*/
$primary-color: #37474f;
$primary-light-color: #78909c;
$primary-dark-color: #263238;

/*secondary elements*/
$secondary-color: #ff6d00;
$secondary-light-color: #ff9e40;
$secondary-dark-color: #c43c00;

/*basic background*/
$background-color: #ffffff;
/*cards and like this*/
$surface-color: #f5f5f5;

$error-color: #dd2c00;

$text-color: #ffffff;
$text-color-dark: #757575;
$text-contrast-color: #000000;

$text-gray: rgb(165, 161, 161);

/* colors v2 */
$blue-dark: rgb(16, 31, 119);
$blue-light: rgb(56, 172, 201);
$subheader-bg: rgb(233, 248, 255);
$color-blue-subheader: rgb(26, 80, 161);
$blue-extralight: rgb(212, 246, 255);
$color-white: #fff;
$wizard-bg-color: rgb(234, 239, 247);
$color-green: rgb(0, 148, 0);

//FONTS
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600;700&display=swap');
$font-primary: 'Poppins', sans-serif;

body {
  font-family: $font-primary;
}

//BORDERS
$border-primary: 1px solid rgba(26, 80, 161, 0.116);

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html {
  box-sizing: border-box;
  font-size: 70%;
}

body {
  font-family: $font-primary;
}

//GLOBAL CLASSES

.pushRight {
  margin-right: 2.5rem;
}

.pushLeft {
  margin-left: 2.5rem;
}

.align {
  transform: translateY(3px);
}

// gets rid of global text underline on all link components
a {
  text-decoration: none;
}

#expand-icon {
  opacity: 1 !important;
  color: #fff;
}

.MuiMenuItem-root {
  font-family: $font-primary !important;
}

//PAGINATOR COLOR
.MuiPaginationItem-textPrimary.Mui-selected {
  background-color: rgb(56, 172, 201) !important;
}

//TABS FOR SUB-NAV WITH ICONS
.MuiTab-wrapper {
  flex-direction: row !important;
}

//FONT WEIGHT FOR ALL NAV ELEMENTS
.MuiButton-label {
  font-weight: 500;
  font-size: 1.1rem;
}

.MuiDialog-paperWidthSm {
  max-width: 630px !important;
}

#hover:hover {
  cursor: pointer;
}

#hover-notchecked:hover {
  cursor: pointer;
}

.margin-extra {
  margin-top: 2rem;
}

#hover-underline:hover {
  text-decoration: underline;
  cursor: pointer;
}

// ======== GLOBAL STYLES ========= //
// TODO: slowly begin overwritting existing styles with these global styles where applicable //

// ==== TITLES === //
.title-lrg {
  color: $color-blue-subheader;
  font-size: 2.5rem;
}

.title-med {
  color: $color-blue-subheader;
  font-size: 1.5rem;
}

.title-small {
  // TODO: APPLY TO ALL FORM LABELS ON H2 TAG
  color: $color-blue-subheader;
  font-size: 1.4rem;
  font-weight: 600;
}

// ==== BUTTONS === //
.btn-blue {
  // TODO: APPLY TO ALL FORM BUTTONS
  background-color: $color-blue-subheader;
  color: #fff;
  font-weight: 600;
  font-family: $font-primary;
  font-size: 1.3rem;
  border-color: transparent;
  border-radius: 100vh;
  padding: 0.4rem 1.5rem;
  transition: 300ms;
}

.btn-blue:hover {
  cursor: pointer;
  transform: translateY(-2px);
  // box-shadow: rgba(100, 100, 111, 0.103) 0px 7px 19px 0px;
}

.invert-btn-colors {
  // TODO: APPLY TO ALL FORM BUTTONS
  background-color: $wizard-bg-color;
  color: $color-blue-subheader;
}

// ===== MARGINS ===== //
.margin-top {
  margin-top: 1rem;
}

.margin-top-extra {
  margin-top: 2rem;
}

.margin-right {
  margin-right: 1rem;
}

.margin-right-extra {
  margin-right: 2rem;
}

.margin-bottom {
  margin-bottom: 1rem;
}

.margin-bottom-extra {
  margin-bottom: 2rem;
}

.margin-remove {
  margin-bottom: -1rem;
}
