@import '../../../../styles/colors.scss';

.LocationCard {
  min-height: 31.5rem;
  width: 25rem;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  margin: 2rem;

  &__row1 {
    display: flex;
    justify-content: center;
    border-bottom: 1px solid rgba(99, 99, 99, 0.096);
  }

  &__row2 {
    height: auto;
    margin-bottom: 2rem;

    &__address {
      display: flex;
      align-items: center;
      padding: 0 1rem;

      &__row {
        width: 100%;
        display: flex;
        align-items: center;
      }

      &__row > p {
        font-size: 1.2rem;
        font-weight: 300;
        margin-right: 1rem;
        color: black;
        font-family: $font-primary;
      }

      &__row > span {
        color: $color-blue-subheader;
        font-size: 1.8rem;
        margin-right: 0.5rem;
        margin-left: 1rem;
        margin-top: 0.5rem;
      }
    }
  }

  &__row2 > h2 {
    color: $color-blue-subheader;
    font-weight: 600;
    text-align: center;
    margin: 1rem;
  }

  &__row3 {
    border-top: 1px solid rgba(99, 99, 99, 0.096);
    height: 4rem;
    display: flex;

    &__col2 {
      width: 50%;
      height: 100%;
      border-right: 1px solid rgba(99, 99, 99, 0.096);
      display: flex;
      justify-content: center;
      align-items: center;
    }
    &__col3 {
      width: 50%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

.LocationCard__Add-New {
  height: 31.3rem;
  width: 25rem;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  margin-left: 2rem;
  margin-top: 3.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &__Plus {
    font-size: 4rem;
    margin-bottom: 1.5rem;
    color: $blue-light;
    transition: 300ms;
  }

  &__Plus:hover {
    cursor: pointer;
    transform: scale(1.05);
  }
}

.LocationCard__Add-New > p {
  color: $color-blue-subheader;
  font-weight: 600;
  font-size: 1.5rem;
}
