@import '../../../styles/colors.scss';

.ServiceDiscoveryPage {
  height: 80vh;
  width: 100vw;

  &__filters {
    margin: 1.5rem 0;
    height: 4rem;
    display: flex;
    align-items: center;
    padding-left: 10rem;
    position: relative;

    div {
      width: 25rem;
      border-radius: 5px;
      background-color: white;
      box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
      position: absolute;
      display: flex;
      align-items: center;
      bottom: -4rem;
      left: 24rem;
      z-index: 200;
      padding: 0.5rem 2rem;

      p {
        font-size: 1.4rem;
        margin-left: 1rem;
        color: $color-blue-subheader;
        font-weight: 600;
      }
    }

    p {
      font-weight: 500;
      font-size: 1.2rem;
      margin-right: 1rem;
    }

    button {
      margin: 0 0.5rem;
      border-radius: 100vh;
      font-family: $font-primary;
      border-color: transparent;
      color: $color-blue-subheader;
      padding: 0.4rem 1rem;
      background-color: $wizard-bg-color;
    }

    button:hover {
      cursor: pointer;
    }
  }

  &__container {
    width: 100%;
    height: 90%;
    display: grid;
    grid-template-columns: minmax(35rem, 1.5fr) 3fr;

    &__cards {
      height: 100%;
      padding-left: 5vw;
      // padding-right: 1rem;
      overflow-y: scroll;

      &__card {
        width: 94%;
        height: auto;
        border-radius: 5px;
        margin: 2rem 0;
        box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
        display: flex;
        // flex-wrap: wrap;
        // display: none;

        &__col-1 {
          width: 38%;

          background-color: $wizard-bg-color;
          border-top-left-radius: 5px;
          border-bottom-left-radius: 5px;
          display: flex;
          align-items: center;
          justify-content: center;

          img {
            height: 8rem;
            width: 100%;
            object-fit: cover;
          }
        }

        &__col-2 {
          // width: 10rem;
          // border: 1px solid red;
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          padding: 1rem;

          &__row-1 {
            display: flex;
            justify-content: space-evenly;

            padding-left: 1rem;
            align-items: center;
            width: 100%;

            &__titles {
              display: flex;

              h3 {
                color: $color-blue-subheader;
                font-size: 1.8rem;
                font-weight: 600;
                align-self: center;
              }

              h5 {
                font-weight: 600;
                font-size: 1.1rem;
              }
            }
          }

          &__row-2 {
            margin-top: 1rem;
            font-weight: 300;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            text-align: center;
            height: 5rem;
            padding: 1.3rem;
            // text-overflow: ellipsis;
            // white-space: nowrap;
            text-overflow: clip;

            h4 {
              color: $blue-dark;
              font-weight: 500;
              font-size: 1.4rem;
            }

            p {
              text-overflow: clip;
            }
          }

          &__row-3 {
            border-top: 1px solid rgba(128, 128, 128, 0.185);
            width: 100%;
            margin-top: 1.5rem;
            display: flex;
            align-items: center;
            justify-content: center;
            padding-top: 0.5rem;
          }
        }
      }
      &__card:hover {
        cursor: pointer;
      }
    }

    &__map {
      height: 100%;
    }
  }

  &__info-marker {
    height: auto;
    width: 21rem;

    &__rating {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 1rem;
      margin-top: -1rem;

      span {
        font-weight: 500;
        font-size: 1.2rem;
      }

      p {
        color: $color-blue-subheader;
        font-size: 1.1rem;
      }
    }

    img {
      height: 7rem;
      width: 100%;
      background-color: $wizard-bg-color;
      object-fit: contain;
    }

    img:nth-child(2) {
      border-radius: 50%;
      width: 7rem;
      height: 7rem;
      object-fit: cover;
      margin-top: -5rem;
      border: 3px solid white;
      background-color: white;
    }

    h3 {
      color: $color-blue-subheader;
      font-size: 1.5rem;
      padding: 0.5rem;
    }

    h4 {
      font-weight: 500;
      font-size: 1.5rem;
      color: $color-blue-subheader;
    }

    p {
      margin: 1rem;
    }
  }
}

#card-active {
  transform: scale(1.03);
  transition: 100ms;
  border: 2px solid $color-blue-subheader;
}

.gm-style .gm-style-iw-c {
  padding: 0;
}
.gm-style-iw-d {
  overflow: hidden !important;
}

#btn-wrapped {
  border: 2px solid $color-blue-subheader;
  transition: 100ms;
}

.Modal-Service {
  height: 100%;
  padding: 1rem;

  &__titles {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: $wizard-bg-color;
    border-radius: 5px;
    padding: 1rem;

    h2 {
      color: $color-blue-subheader;
      font-size: 2.4rem;
    }

    h4 {
      font-weight: 600;
      color: gray;
      color: $color-blue-subheader;
      font-size: 1.4rem;
    }

    p {
      // color: $color-blue-subheader;
      font-size: 1.1rem;
      font-weight: 300;
    }
  }

  &__contact {
    height: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1rem;
    border: 1px solid $wizard-bg-color;
    border-radius: 5px;
    margin: 1rem 0;

    h2 {
      color: $color-blue-subheader;
    }

    &__container {
      &__row {
        display: flex;
        align-items: center;

        span {
          font-size: 1.5rem;
          margin-right: 1rem;
          color: rgb(71, 71, 71);
        }

        p {
          font-size: 1.1rem;
          font-weight: 500;
          margin-top: -0.5rem;
        }
      }
    }
  }

  &__services {
    border: 1px solid pink;
    height: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1rem;
    border: 1px solid $wizard-bg-color;
    border-radius: 5px;
    margin: 1rem 0;

    h2 {
      color: $color-blue-subheader;
    }
  }

  &__focus {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1rem;
    border: 1px solid $wizard-bg-color;
    border-radius: 5px;

    h2 {
      color: $color-blue-subheader;
    }

    &__container {
      margin-top: 0.5rem;
      &__row {
        display: flex;

        span {
          color: green;
          font-size: 1.4rem;
          margin-right: 1rem;
        }

        p {
          font-size: 1.1rem;
          font-weight: 500;
        }
      }
    }
  }

  &__Carousel {
    border-radius: 5px;
    // border: 1px solid red;
    // height: 10rem;
    height: auto;
    width: 100%;

    &__container {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      justify-content: center;
      // height: 10rem;
      width: 100%;
      margin: auto;
      padding: 1rem;

      &__row {
        display: flex;
        align-items: center;

        span {
          font-size: 1.8rem;
          margin-right: 1rem;
          color: rgb(71, 71, 71);
        }

        h4 {
          font-size: 1.4rem;
          color: gray;
        }

        p {
          font-size: 1.1rem;
          font-weight: 500;
        }
      }
    }
  }
}
