@import '../../../styles/colors.scss';

.Stepper {
  padding: 2rem 3rem !important;
  min-height: 45vh;
  display: flex;
  flex-direction: column;

  &__title > * {
    color: $color-blue-subheader;
    font-family: $font-primary !important;
    font-size: 1.7rem !important;
    font-weight: 600 !important;
  }
}

//=======TODO: MOST OF THIS NEEDS TO BE REMOVED AND APPLIED INDIVIDUALLY WHERE APPROPRIATE========//

.MuiStepLabel-label.MuiStepLabel-alternativeLabel {
  font-size: 1.5rem !important;
  font-family: $font-primary;
  margin-top: 0 !important;
  color: rgb(92, 92, 92);
  opacity: 0.3;
  font-weight: 600;

  margin-left: 1rem !important;
}

.MuiStepLabel-label.MuiStepLabel-active {
  color: $color-blue-subheader !important;
  font-weight: 600 !important;
  opacity: 1;
}

.MuiStepLabel-root.MuiStepLabel-alternativeLabel {
  flex-direction: row !important;
}

.MuiStepIcon-text {
  font-size: 1.2rem !important;
  font-family: $font-primary !important;
  font-weight: 600 !important;
  transform: translateY(1px) !important;
}

.MuiSvgIcon-root {
  font-size: 1.8rem !important;
  color: $color-blue-subheader;
  opacity: 0.3;
}

.MuiStepLabel-label.MuiStepLabel-completed {
  font-family: $font-primary !important;
  font-weight: 600 !important;
  color: $color-blue-subheader !important;
  opacity: 1;
}

.MuiStepIcon-root.MuiStepIcon-completed {
  opacity: 1;
  color: $color-green !important;
}

.MuiStepIcon-root.MuiStepIcon-active {
  color: $color-blue-subheader !important;
  opacity: 1;
  font-size: 1.8rem;
}

.MuiStepConnector-line {
  display: none !important;
}

.MuiStepper-root {
  padding: 1rem !important;
  margin-bottom: 2rem !important;
  background-color: $wizard-bg-color !important;
}

#additional-field-styles {
  color: black !important;
  font-size: 1.1rem;
}

#additional-field-styles::placeholder {
  color: rgba(0, 0, 0, 0.432);
}

.MuiButton-contained.Mui-disabled {
  font-family: $font-primary !important;
  text-transform: capitalize !important;
  background-color: rgb(182, 180, 180) !important;
  font-size: 1.3rem;
  font-weight: 600;
  opacity: 0.6;
  color: rgb(238, 238, 238) !important;
}

.MuiButton-containedPrimary {
  background-color: $color-blue-subheader !important;
  font-family: $font-primary !important;
  text-transform: capitalize !important;
  font-size: 1.3rem !important;
  font-weight: 600 !important;
  border-radius: 35px !important;
  font-size: 1.4rem !important;
  box-shadow: none !important;
  transition: transform 300ms !important;
}

#radio-additional-styles {
  margin-bottom: 1rem;
  margin-top: 1rem;
  margin-left: 1rem;
}

#radio-additional-styles > * {
  color: black !important;
}

.MuiTypography-body1 {
  font-family: $font-primary !important;
}

.MuiInputBase-input {
  color: black !important;
}

#btn-back {
  border-radius: 35px;
  background-color: $wizard-bg-color;
  margin-right: 1rem;
  color: $color-blue-subheader;
  font-size: 1.3rem;
  padding: 0 1rem;
  font-family: $font-primary;
  font-weight: 600;
  text-transform: capitalize;
  transition: transform 300ms;
}

#btn-back:hover {
  transform: translateY(-2px);
}

#btn-hover-fx:hover {
  transform: translateY(-2px);
}

.ServiceHoursTitle {
  display: flex;
  align-items: center;

  p {
    font-size: 1.1rem;
    margin-right: 0.5rem;
    color: $color-blue-subheader;
    transform: translateY(2px);
  }

  p:hover {
    cursor: pointer;
  }

  h2 {
    color: $color-blue-subheader;
    font-size: 1.3rem;
    font-weight: 600;
  }

  span {
    margin-left: 0.5rem;
    font-size: 1rem;
    color: $color-blue-subheader;
    transform: translateY(3px);
  }

  span:hover {
    cursor: pointer;
  }
}

.anim-rotate {
  animation: rotate-forwards 300ms forwards;
}

.anim-back {
  animation: rotate-back 300ms forwards;
}

@keyframes rotate-forwards {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(180deg);
  }
}

@keyframes rotate-back {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(-360deg);
  }
}
