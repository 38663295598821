@import '../../../../../styles/colors.scss';

.UserServiceListCard {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  border-radius: 5px;
  margin: 1.5rem;
  height: auto;
  width: 23rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  &__avatar {
    border: 3px solid rgb(253, 253, 253);
    border-radius: 50% !important;
    width: 8rem !important;
    height: 8rem !important;
    object-fit: contain;
    margin-top: -3rem;
    background-color: $blue-dark;
  }

  img {
    width: 100%;
    height: 8rem;
    object-fit: cover;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }

  h2 {
    color: $color-blue-subheader;
    font-weight: 600;
    margin-top: 1rem;
    font-size: 1.5rem;
  }

  p {
    padding: 2rem;
    margin: 1rem 0;
    font-weight: 300;
    font-size: 1.1rem;
  }

  &__row2 {
    border-top: 1px solid $wizard-bg-color;
    width: 100%;
    height: 3rem;
    margin-top: auto;
  }
}
