@import '../../styles/colors.scss';
@import '../../styles/variables.scss';

.SignInPage-Container {
  min-height: 85vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;

  &__form {
    min-width: 60%;
    height: 40vh;
    display: flex;
    flex-direction: column;
    align-items: center;

    &__title {
      font-size: 2.5rem;
      margin-bottom: 4rem;
      font-weight: 600;
      color: $color-blue-subheader;
    }

    &__row {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      border: 1px solid green;
    }

    input {
      font-size: 1em;
      padding: $size-xs 0;
      display: block;
      width: 100%;
      max-width: 50rem;
      background-color: rgb(232, 241, 253) !important;
      border: none;
      border-bottom: 1px solid $primary-color;
      &:focus {
        outline: none;
      }
      &::placeholder {
        font-family: $font-primary;
        color: rgb(175, 175, 175);
      }
    }
  }

  label {
    color: rgb(146, 146, 146);
    font-weight: 600;
    pointer-events: none;
    transition: 0.2s ease all;
    font-size: 1.5rem;
    align-self: start;
    user-select: none;
  }

  input:focus ~ .bar:before,
  input:focus ~ .bar:after {
    width: 50%;
  }
  &.error {
    input {
      border-color: $error-color;
    }
    color: $error-color;
  }

  input:focus + label {
    color: $color-blue-subheader;
  }

  &__forgot-pass {
    color: black !important;
    font-size: 1.2rem;
    margin-top: 2rem;
  }

  &__forgot-pass:hover {
    text-decoration: underline;
  }

  &__btn-back {
    margin-top: 3rem;
    font-size: 2.5rem;
    color: $color-blue-subheader;
    transition: all 300ms;
  }

  &__btn-back:hover {
    cursor: pointer;
    transform: scale(1.1);
  }
}

.SetPasswordContainer {
  margin-top: 5rem;

  &__row {
    display: flex;
    align-items: center;
    justify-content: center;

    &__not-valid {
      opacity: 0.3;
    }

    &__valid {
      color: green;
      font-weight: 600;
    }
  }

  &__row > p {
    font-size: 1.4rem;
    margin-left: 1rem;
  }

  &__row > span {
    font-size: 1.5rem;
  }

  h2 {
    font-size: 2rem;
    font-weight: 600;
    color: $color-blue-subheader;
    margin-bottom: 0.5rem;
    user-select: none;
  }
}

#btn-back-disabled {
  border-radius: 35px;
  background-color: gray;
  opacity: 0.2;
  margin-right: 1rem;
  color: $color-blue-subheader;
  font-size: 1.3rem;
  font-family: $font-primary;
  font-weight: 600;
  text-transform: capitalize;
  transition: transform 300ms;
  margin-top: 2rem;
  box-shadow: none;
  color: white;
  padding: 0.6rem 1.2rem;
}

.bar {
  position: relative;
  display: block;
  width: 94%;
  transform: translateX(-1px);
  &:before,
  &:after {
    content: '';
    height: 2px;
    width: 0;
    bottom: 0;
    position: absolute;
    background: $color-blue-subheader;
    transition: 0.2s ease all;
  }
  &:before {
    left: 50%;
  }
  &:after {
    right: 50%;
  }
}

.setPasswordPage-container {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;
  flex-direction: column;

  &__col {
    display: flex;
    flex-direction: column-reverse;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    max-width: 50rem;
  }

  &__row {
    display: flex;
    align-items: center;
    width: 100%;
  }
}

.span-bar {
  border: 0;
  width: 45rem;
  max-width: 45rem;
  border-top: 3px solid $color-blue-subheader;
  padding: 0;
  transform: translateY(-3px) translateX(-13px);
  animation: expand 400ms ease-out forwards;
}

@keyframes expand {
  0% {
    width: 0%;
  }
  100% {
    width: 90%;
  }
}

.neg-margin-top {
  margin-top: -3px;
}
