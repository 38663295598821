@import '../../../../styles/colors.scss';

.ServiceNeedDialogText {
  border-radius: 5px;
  margin: 1.5rem;
  font-size: 1.3rem;
  font-weight: 500;
  display: flex;
  height: 8rem;
  width: 40rem;
  background-color: $wizard-bg-color;
  position: relative;

  p {
    position: absolute;
    right: 0;
    margin-right: 0.5rem;
    margin-top: 0.4rem;
    color: $color-blue-subheader;
    font-size: 1.1rem;
    font-weight: 600;
  }

  p:hover {
    cursor: pointer;
  }

  &__col-1 {
    background-color: $color-blue-subheader;
    padding: 0.5rem;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;

    img {
      height: 100%;
      width: 100%;
    }
  }

  &__col-2 {
    padding-left: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;

    &__row {
      display: flex;
      align-items: center;
      // margin-top: 0.5rem;

      span {
        color: $color-blue-subheader;
        font-size: 2.4rem;
      }
    }

    h3 {
      color: $color-blue-subheader;
      font-size: 1.8rem;
      margin-left: 1rem;
    }

    p {
      color: rgb(41, 41, 41);
      margin-left: 3rem;
      margin-top: 0.3rem;
    }
  }
}

.CareTeamDialog {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  border-radius: 5px;
  margin: 1rem;
  font-size: 1.3rem;
  padding: 1.5rem;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: space-between;

  h2 {
    color: $color-blue-subheader;
    font-weight: 600;
    font-size: 1.3rem;
    margin-left: -2rem;
  }

  p {
    font-weight: 300;
    color: rgb(88, 88, 88);
    font-size: 1rem;
  }

  span {
    border: 1px solid $secondary-light-color;
    background-color: $secondary-light-color;
    color: #fff;
    border-radius: 15px;
    padding: 0.2rem 1rem;
    font-size: 0.8rem;
  }
}

@media only screen and (max-width: 650px) {
  .CareTeamDialog {
    flex-direction: column;
  }

  h2 {
    margin-top: 0.5rem;
    margin-bottom: 0.3rem;
  }

  p {
    margin-bottom: 0.5rem;
  }
}

.ContactInfoDialog {
  display: flex;
  flex-direction: column;

  &__row {
    display: flex;

    span {
      font-size: 2rem;
      margin-right: 2rem;
      color: gray;
    }

    h2 {
      color: gray;
      font-weight: 500;
    }
  }
}

.Provider-Service-TeamMember-Card {
  margin: 1rem;
  padding: 1.5rem 1rem;
  border-radius: 5px;
  width: 100%;
  width: 30rem;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;

  h3 {
    color: $color-blue-subheader;
    font-size: 1.5rem;
  }

  p {
    font-size: 1.1rem;
    color: gray;
  }
}

#Provider-Service-TeamMember-Card-inactive {
  opacity: 0.4;
}

#Provider-Service-TeamMember-Card-inactive:hover {
  cursor: pointer;
}

.Provider-Service-TeamMember-Card:hover {
  cursor: pointer;
}
