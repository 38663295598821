@import '../../../styles/colors.scss';

.MobileHeader {
  background-image: linear-gradient(to right, $blue-dark, $blue-light);
  height: 5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;

  &__avatar {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 1rem;
  }

  &__content {
    height: auto;
    padding-top: 0.5rem;
    border: $border-primary;
    background-color: #fff;
    width: 20rem;
    position: absolute;
    z-index: 100;
    animation: height-in 200ms ease-out forwards;
    transition: 200ms;
    border-radius: 5px;
    border-bottom-right-radius: 8px;
    transform: translateY(-3px);

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    &__none {
      display: none;
    }

    &__row {
      width: 100%;
      display: flex;
      border-bottom: $border-primary;
      align-items: center;
    }

    &__row:hover {
      cursor: pointer;
      background-color: rgb(234, 239, 247);
    }

    &__row > p {
      font-size: 1.3rem;
      color: $color-blue-subheader;
      margin-left: 0.5rem;
      user-select: none;
      font-weight: 600;
    }

    &__row > span {
      margin: 0.5rem;
    }
  }
}

@media only screen and (min-width: 620.5px) {
  .MobileHeader {
    display: none;
  }
}

@media only screen and (min-width: 620.5px) {
  .MobileHeader__content {
    display: none;
  }
}

.flipped {
  transform: rotate(90deg);
  transition: all 300ms;
}

.not-flipped {
  transform: rotate(0);
  transition: all 300ms;
}

#hamburger-icon {
  cursor: pointer;
}

.disabled {
  opacity: 0.2;
}

.disabled:hover {
  cursor: default;
}

//====== ANIMS =======//
@keyframes height-in {
  0% {
    height: 0rem;
  }
  100% {
    height: auto;
  }
}
