@import '../../styles/colors.scss';

.Header {
  background-image: linear-gradient(to right, $blue-dark, $blue-light);
  height: 6rem;
  display: flex;
  width: 100vw;

  &__col-1 {
    display: flex;
    align-items: center;
    margin-left: 7rem;
    height: 100%;
    justify-content: center;

    &__location {
      margin-left: 4rem;
      padding: 0.3rem 0.5rem;
      background-color: rgba(255, 255, 255, 0.082);
      border-radius: 25px;
      display: flex;
      color: $color-white;
      align-items: center;
    }

    &__location > span {
      margin-left: 1.2rem;
      font-size: 1.2rem;
      color: rgba(255, 255, 255, 0.404);
    }
  }

  &__col-1 > h3 {
    font-size: 2rem;
    color: $color-white;
    padding-left: 1rem;
    font-weight: 600;
  }

  &__col-1 > h3 > span {
    font-size: 2.3rem;
  }

  &__col-2 {
    display: flex;
    align-items: center;
    height: 100%;
    justify-content: flex-end;
    margin-right: 8rem;
  }

  &__col-2 > img {
    height: 3.3rem;
    width: 3.3rem;
    object-fit: cover;
    border-radius: 50%;
  }

  &__col-2 > p {
    color: $color-white;
    font-size: 1.2rem;
    font-weight: 300;
    margin: 0.5rem;
  }

  &__col-2 > span {
    color: $color-white;
  }
}

.Header > div:last-child {
  margin-left: auto;
}

@media only screen and (max-width: 620px) {
  .toggle-mobile-nav {
    display: none;
  }
}

@media only screen and (max-width: 620px) {
  .Header {
    display: none;
  }
}

@media only screen and (max-width: 620px) {
  #subheader {
    display: none;
  }
}
