@import '../../../styles/colors.scss';

.ProviderClientsPage {
  min-height: 78vh;
  display: flex;
  flex-direction: column;
  align-items: center;

  &__labels {
    width: 80vw;
    display: flex;
    justify-content: space-between;
    margin-top: 1rem;

    p {
      font-size: 1.2rem;
      font-weight: 600;
      color: $color-blue-subheader;
    }

    p:nth-of-type(6) {
      visibility: hidden;
    }

    p:nth-of-type(5) {
      transform: translateX(3.5rem);
    }

    p:nth-of-type(4) {
      transform: translateX(3.4rem);
    }

    p:nth-of-type(3) {
      transform: translateX(1.4rem);
    }

    p:nth-of-type(2) {
      transform: translateX(1.5rem);
    }

    p:nth-of-type(1) {
      transform: translateX(1.5rem);
    }
  }

  @media screen and (max-width: 785px) {
    &__labels {
      display: none;
    }
  }

  &__client-details-container {
    width: 100%;
    display: flex;
    justify-content: center;
    margin: 1rem;
    flex-wrap: wrap;
  }

  @media only screen and (max-width: 520px) {
    &__client-details-container {
      flex-wrap: nowrap;
      overflow-x: scroll;
      justify-content: flex-start;
      padding: 1rem;
    }
  }

  &__row {
    width: 80vw;
    display: flex;
    justify-content: flex-end;
    margin: 2rem 0;

    input {
      border-radius: 5px;
      border: 1px solid rgb(186, 197, 216);
      padding: 0.6rem;
      width: 18rem;
      transition: all 300ms;
    }

    input:focus {
      width: 19rem;
      outline: none;
    }

    input::placeholder {
      color: rgb(186, 197, 216);
      font-family: $font-primary;
    }

    &__icon {
      font-size: 1.8rem;
      color: rgb(186, 197, 216);
      align-self: center;
      transform: translateX(-24px);
    }

    &__add-new-btn {
      background-color: $blue-light;
      border: none;
      color: #fff;
      width: 3rem;
      height: 3rem;
      border-radius: 50%;
      transition: all 300ms;
    }

    &__add-new-btn:hover {
      cursor: pointer;
      transform: translateY(-2px);
    }
  }

  @media screen and (max-width: 785px) {
    &__row {
      justify-content: center;
    }
  }
}

.ClientListView {
  margin: 1rem 0;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  display: flex;
  height: auto;
  width: 100%;
  align-items: center;

  &__avatar {
    margin: 1rem;
  }

  &__row {
    display: flex;
    align-items: center;
    min-width: 15rem;
  }

  &__row2 {
    display: flex;
    width: 100%;
    justify-content: space-around;
    position: relative;

    &__needs {
      &__text {
        display: none;
      }
      p {
        font-size: 1.2rem;
        margin-right: 1rem;
      }

      span {
        background-color: rgba(80, 220, 255, 0.13);
        width: 3rem;
        height: 3rem;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      span:hover {
        cursor: pointer;
      }
    }

    div {
      display: flex;
      align-items: center;
    }
  }

  h5 {
    color: rgb(48, 47, 47);
    font-weight: 600;
    font-size: 1.1rem;
    text-transform: capitalize;
  }

  &__info-panel {
    display: flex;
    justify-content: space-between;

    p {
      font-weight: 500;
      font-size: 1.1rem;
      margin: 0 2rem;
    }
  }
}

@media screen and (max-width: 785px) {
  .ClientListView {
    flex-direction: column;
    width: 25rem;
    align-items: flex-start;
    margin: 1rem;

    &__row2 {
      display: block;
      padding: 1rem;

      &__needs {
        margin-bottom: 1rem;

        &__text {
          display: block;
          font-weight: 600;
          font-size: 1.2rem;
          color: $color-blue-subheader;
        }
      }
    }

    h5 {
      font-size: 1.4rem;
    }

    &__row2 > div:nth-of-type(3) > p {
      font-size: 1.2rem;
      margin-left: 1rem;
    }

    &__row2 > div:nth-of-type(2) {
      margin-top: -0.5rem;
    }

    &__row2 > div:nth-of-type(4) {
      margin-top: 1rem;
      margin-bottom: 1.5rem;
    }
  }
  .ClientListView > div:last-child {
    align-self: flex-end;
    margin-top: -5rem;
    margin-bottom: 1.5rem;
  }
}

.NeedsDetailsCard {
  width: 14rem;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  padding: 1rem;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  position: absolute;
  background-color: #fff;
  z-index: 100;
}

@media only screen and (max-width: 620px) {
  .ProviderClientsPage {
    min-height: 86vh;
  }
}
