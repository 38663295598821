@import '../../../styles/colors.scss';

.LocationFormPopup {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  h3 {
    color: $color-blue-subheader;
    font-size: 1.3rem;
  }

  span {
    color: white;
    padding: 0 1rem;
    border-radius: 25px;
    margin-top: 0.3rem;
    font-weight: 600;
    font-size: 0.8rem;
    margin-bottom: 0.5rem;
  }
}
