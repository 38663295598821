@import '../../styles/colors.scss';

.Footer {
  border-top: 2px solid rgba(0, 0, 0, 0.089);
  height: 22rem;
  display: flex;

  &__col-1 {
    height: 100%;
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 1rem;

    &__row {
      display: flex;
      align-items: center;
      margin-left: 3rem;
      margin-bottom: 1rem;
    }

    &__row > h2 {
      margin-left: 0.5rem;
      font-size: 1.8rem;
      font-weight: 600;
      color: $blue-dark;
    }
  }

  &__col-1 > p {
    margin-left: 3rem;
    margin-right: 5rem;
    font-size: 1.1rem;
    color: rgb(110, 110, 110);
    margin-bottom: 2rem;
  }

  &__col-1 > a {
    margin-left: 3rem;
    font-size: 1.1rem;
    color: $blue-light;
  }

  &__col-2 {
    height: 100%;
    width: 20%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    &__row {
      display: flex;
      margin: 0.3rem;
    }

    &__row > span {
      color: $color-blue-subheader;
      font-size: 1.5rem;
      margin-right: 0.8rem;
    }

    &__row > p {
      font-size: 1.1rem;
      color: rgb(110, 110, 110);
    }
  }

  &__col-2 > h3 {
    color: $color-blue-subheader;
    font-size: 1.4rem;
    font-weight: 600;
    margin-bottom: 1rem;
  }

  &__col-3 {
    height: 100%;
    width: 30%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    &__row {
      display: flex;
    }

    &__row > * {
      font-size: 2.3rem;
      margin-right: 1.5rem;
    }

    &__row > *:first-child {
      margin-left: 3rem;
    }
  }

  &__col-3 > * {
    margin: 0.6rem;
  }

  &__col-3 > h3 {
    margin-left: 3rem;
    font-size: 1.4rem;
    font-weight: 600;
    color: $color-blue-subheader;
  }

  &__col-3 > p {
    font-size: 1.1rem;
    color: rgb(110, 110, 110);
    margin-left: 3rem;
  }

  &__col-3 > input {
    margin-left: 3rem;
    width: 70%;
    padding: 0.7rem;
    border: 1px solid rgba(0, 0, 0, 0.103);
    border-radius: 5px;
  }

  &__col-3 > input::-webkit-input-placeholder {
    font-family: $font-primary;
    color: rgba(0, 0, 0, 0.103);
  }

  // ^^^ ABOVE STYLES ARE FOR SUB-FOOTER COMPONENT WHICH DOES NOT CURRENTLY EXIST ON THIS PROJECT ^^^ //
  &__Copyright {
    background-image: linear-gradient(to right, $blue-dark, $blue-light);
    height: 5rem;
    width: 100%;
    margin-top: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &__col-1 {
      color: white;
      font-weight: 300;
      font-size: 1.2rem;
      margin-left: 4rem;
    }

    &__col-2 {
      color: white;
      font-weight: 300;
      font-size: 1.2rem;
      display: flex;
      margin-right: 4rem;

      &__text {
        margin: 1rem;
        transition: 300ms;
        color: #fff;
      }

      &__text:hover {
        text-decoration: underline;
      }
    }
  }
}
