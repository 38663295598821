@import '../../../../styles/colors.scss';

.ProfileDetailsPanel > * {
  font-family: $font-primary;
}

.ProfileDetailsPanel {
  &__row {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
