@import '../../../styles/colors.scss';

.ClientOnBoarding {
  width: 100%;
  min-height: 57vh;
  display: flex;
  flex-direction: column;
  align-items: center;

  &__summary {
    height: auto;
    width: 100%;

    max-width: 55rem;
    display: flex;
    justify-content: center;

    &__row {
      margin-left: 2rem;

      width: 50%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      word-break: break-word;
      text-overflow: ellipsis;
    }

    h2 {
      color: $color-blue-subheader;
      font-size: 1.8rem;
      margin-top: 1rem;
    }

    p {
      font-size: 1.4rem;
      color: rgb(59, 59, 59);
    }
  }

  &__options {
    width: 100%;
    max-width: 70rem;
    height: auto;
    padding: 1rem;
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    justify-content: center;

    &__option {
      width: auto;
      margin: 1rem;
      padding: 1rem 1.5rem;
      border-radius: 25px;
      background-color: $wizard-bg-color;
      opacity: 0.7;

      p {
        color: $color-blue-subheader;
        font-size: 1.4rem;
        font-weight: 600;
      }
    }

    &__option:hover {
      cursor: pointer;
    }
  }

  &__form {
    width: 100%;
    max-width: 55rem;
    padding: 0 1rem;
    margin: 0 1rem;
    display: flex;
    flex-direction: column;
    text-align: start;

    h6 {
      color: $color-blue-subheader;
      font-size: 1.5rem;
      margin-bottom: 0.3rem;
    }

    input {
      width: 100%;
      padding: 0.8rem;
      margin-bottom: 2rem;
      border: 1px solid rgba(51, 91, 128, 0.288);
      border-radius: 5px;
    }

    input::placeholder {
      font-family: $font-primary;
      color: rgba(51, 91, 128, 0.288);
    }

    &__birth-date {
      margin-top: -0.5rem;
      display: flex;

      &__row {
        margin-top: 0.5rem;
        margin-right: 0.5rem;

        input {
          margin-right: 1rem;
          width: 3.8rem;
        }

        input:first-child {
          margin: 0 0;
          margin-right: 1rem;
          width: 4rem !important;
          border: 2px solid red;
        }

        p {
          color: gray;
          font-size: 1.2rem;
          font-weight: 600;
          margin-bottom: 0.3rem;
          margin-left: 0.2rem;
        }
      }
    }
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

input[type='text'] {
  font-family: $font-primary;
}

.selected {
  background-color: $color-blue-subheader;
  opacity: 1;

  p {
    color: white;

    span {
      margin-right: 0.5rem;
      padding: 0 0.5rem;
    }
  }
}

#hover:hover {
  cursor: pointer;
}

.input-error {
  border: 1px solid red !important;
}
