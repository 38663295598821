.FileUpload {
  margin-top: 1.5rem;
  margin-bottom: 1rem;
  border-radius: 8px;
  padding: 1.5rem;
  width: 100%;
  min-height: 13rem;
  font-family: 'Poppins', sans-serif;
  font-weight: 300;
  font-size: 1.1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.FileUpload > img:hover {
  cursor: pointer;
}

.FileUpload > p > label {
  color: rgb(58, 110, 252);
  text-decoration: underline;
}

.FileUpload > p {
  color: black;
  font-size: 1.2rem;
}

.FileUpload > p > label:hover {
  cursor: pointer;
}

#subText {
  color: rgb(146, 145, 145);
  font-size: 1rem;
}

#label:hover {
  cursor: pointer;
}

#file {
  font-size: 0.8rem;
}
